import React from 'react';
import './Venetia.css';
import {FullBio} from '../../components/FullBio';


export function Venetia () {
  const instagramURL= "https://www.instagram.com/venetialamanna/";
  const bio = "Venetia is a broadcaster and slow fashion campaigner. She is the creator of the 5 star rated podcast Talking Tastebuds, a series about food, wellbeing and the planet; it has amassed 1.8M downloads. Venetia is BBC Radio 1’s Sustainability Expert and the Sustainability Ambassador for The Body Shop. She has discussed issues related to fashion and the climate crisis on BBC Radio 4, BBC World News and BBC 5Live and has written for The Independent, Eco Age and The Glossary."
  const websiteURL="https://www.venetialamanna.com/";
  return(
    <div className='resources'>
      <FullBio name='Venetia' content={bio} instagram={instagramURL} website={websiteURL}/>
    </div>
  );
}