import React from 'react';
import './Ruby.css';
import {FullBio} from '../../components/FullBio';


export function Ruby () {
  const instagramURL= "https://www.instagram.com/rubyamelia18/";
  const bio = "Ruby is a feminist activist, strategist and independent consultant with over 15 years experience working with global and grassroots organisations to advance gender justice and human rights.  Between 2013-2019 she was the Co-Executive Director for FRIDA | The Young Feminist Fund the world’s largest participatory fund exclusively for young feminist organizers. Ruby supports grassroots organisations, international NGOs, private and public foundations, Women’s Funds and UN Agencies to build strategies and funding approaches, design programs, develop participatory grantmaking practices and strengthen their organisations. She has experience working with young women and girls and co-creating initiatives that centre the decision making and power of young people.";
  const websiteURL="https://www.rubyamelia.com/";
  return(
    <div className='resources'>
      <FullBio name='Ruby' content={bio} instagram={instagramURL} website={websiteURL}/>
    </div>
  );
}