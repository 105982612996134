import React from 'react';
import {Route} from 'react-router-dom';
import {Manifesto} from '../../pages';
import {About} from '../../pages';
import {Podcast} from '../../pages';
import {Action} from '../../pages';
import {Resources} from '../../pages';
import {Gina} from '../../pages';
import {Venetia} from '../../pages';
import {Judith} from '../../pages';
import {Swatee} from '../../pages';
import {Ruby} from '../../pages';
import {Devi} from '../../pages';
import {Holly} from '../../pages';
import {Sophie} from '../../pages';
import {Magalie} from '../../pages';

export  function Routes() {
  return (
    <>
    <Route exact path='/' component={Manifesto}/>
    <Route exact path='/about' component={About}/>
    <Route exact path='/podcast' component={Podcast}/>
    <Route exact path='/resources' component={Resources}/>
    <Route exact path='/action' component={Action}/>
    <Route exact path='/about-venetia' component={Venetia}/>
    <Route exact path='/about-judith' component={Judith}/>
    <Route exact path='/about-magalie' component={Magalie}/>
    <Route exact path='/about-swatee' component={Swatee}/>
    <Route exact path='/about-ruby' component={Ruby}/>
    <Route exact path='/about-devi' component={Devi}/>
    <Route exact path='/about-holly' component={Holly}/>
    <Route exact path='/about-sophie' component={Sophie}/>
    <Route exact path='/about-gina' component={Gina}/>
    </>
  )
}