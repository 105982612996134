import React from 'react';
import {useLocation} from 'react-router-dom';
import {Nav} from '..';
import './PageLayout.css';

export function PageLayout({children}) {
  const location = useLocation();
  const occupiesFullWidth = location.pathname !== '/';

  const className = occupiesFullWidth ? 'page-layout__full' : 'page-layout__half';

  return (
    <div className={`page-layout ${className}`}>
      <div className='nav-container'>
        <Nav isCentered={occupiesFullWidth} />
      </div>

      <div className='content-container'>
        {children}
      </div>
    </div>
  )
}