import React from 'react';
import LogoImage from './RWMT_Logo-Pink.png';
import './Logo.css';

export function Logo(){

  return(
    <>
      <img src={LogoImage} alt="Remember who made them Logo" className='logo'/>
    </>
  )
}
