import React from 'react';
import './Resources.css';
// import GlossaryImage from './images/responsiblity.png';

export function Resources () {
  return(
    <div className='resources'>
      <p className='glossary-title'>Glossary</p>
        {/* <img src={GlossaryImage} alt="hands in the air" className='image'/> */}
        <p><strong>Solidarity - </strong> Solidarity is a tool for reducing inequality and social injustice, based on a common feeling or interest and working with others toward a shared purpose or set of goals.</p>
        <p><strong>Economy - </strong> An economy is a system of making and trading things of value, usually divided into goods (physical things) and services (things done by people). Economies can be local, national and global.</p>
        <p><strong>Capitalism - </strong> A political and economic system where a small number of individuals and companies (and not the government) make most of the decisions. They also own most of the ‘means of production’ (how goods and services are created) and operate for profit.</p>
        <p><strong>A new solidarity economy - </strong>A movement to build an alternative to our capitalist world. A solidarity economy always puts the wellbeing of people and planet before corporate profits.</p>
        <p><strong>Supply chain- </strong>The sequence of resources, organisations, people, actions and information all involved in making a product. Supply chains start with raw materials and end when the finished product is delivered to the consumer.</p>
        <p><strong>Value chain - </strong> Similar to a supply chain. Companies in a capitalist economy often analyse value chains and exploit people at the start of them in order to maximise profits.</p>
        <p><strong>The Global North - </strong>A term that refers to ‘Western’ societies, predominantly in Europe and North America and mainly defined by technological advancement, political stability and wealth. These advantages have often come at the expense of the Global South.</p>
        <p><strong>The Global South - </strong> A term that refers to societies that do not fall into the Global North. Many countries in the Global South are poorer than those in the Global North because of the lasting effects of colonialism and ongoing exploitation.</p>
        <p><strong>Redistribution - </strong>Redistribution is the transfer of income and wealth from some individuals to others using mechanisms like taxation, welfare, public services, policy reforms and charity. In the fashion industry, redistribution is a way to equalise the pay gap between people who work at the start of a value chain (for very little pay) and those who are at the end of the value chain (who get paid or profit considerably more).</p>
        <p><strong>Reparations - </strong>A political justice concept that compensates people - usually in the form of money - as a way to make amends for historical exploitation and violations of their human rights.</p>
        <p><strong>The Rana Plaza disaster - </strong>On 24 April 2013, the Rana Plaza building in Dhaka, Bangladesh collapsed within 90 seconds. Five garment factories were housed in the building. The disaster killed 1,134 people and injured more than 2,500 people, most of whom were garment workers. News of the disaster spread global awareness about the dangers and exploitation in the fashion industry.</p>
        <p><strong>Systemic change - </strong> Getting at the root causes of a problem, rather than trying to fix individual symptoms of the problem. To really achieve a fairer, more sustainable fashion industry, we need systemic change.</p>
        <p><strong>Garment workers - </strong>The people who use their skill and talents to gather clothing materials and make the world’s clothes. Many garment workers are poor women in the Global South. They are often paid low, unfair wages, work in unsafe conditions and are denied negotiating power to demand better treatment. </p>
        <p><strong>Garment worker communities - </strong>The communities in which garment workers and their families live. These communities often live close together and rely on the garment industry in order to survive.</p>
        <p><strong>Collective bargaining - </strong>The negotiation of wages and other employment conditions by an organised body like a union or workers’ collective. Because collective bargaining is not always protected by law, many garment factory employers undermine (or even punish) workers’ efforts to negotiate for better wages and conditions.</p>
        <p><strong>Trade unions - </strong>Groups of employees who join together to improve their working conditions and wages. Unions give voice to workers and help them to demand more rights. </p>
        <p><strong>Collectives - </strong>Informal groups of workers, outside of a formal union, who directly join together to represent themselves and fight for greater rights and working conditions. </p>
        <p><strong>Organising - </strong>Sometimes called ‘community organising’, this is a term used to describe bringing people together to take action to overcome social injustice and achieve a shared goal (like a living wage, for example).</p>
        <p><strong>A living wage - </strong>The actual amount of hourly income needed for a working person to live with dignity. The living wage factors in the real costs of food, water, housing, education, healthcare, transport, clothing, individual essentials and provision for unexpected events where a person lives. </p>
        <p><strong>Participatory democracy - </strong>A model of democracy that allows for more participation by individual citizens in the political decisions and policies that affect their lives. This is usually done directly, rather than through elected representatives.</p>
        <p><strong>Corporate accountability - </strong>The idea that corporations should be held accountable, and made to address, the negative impacts they have had on people and on the planet (like contributing to the climate crisis).</p>
        <p><strong>Pluralism - </strong>A principle that allows multiple versions of something to coexist. Pluralism is a principle of the solidarity economy because each community should be able to establish a solidarity economy that responds to specific local needs and conditions.</p>
        <p><strong>Intersectionality - </strong>A theoretical concept coined by Kimberlé Crenshaw to help understand how aspects of our identities (gender, race, class, sexuality, ability, etc.) combine to create unique modes of discrimination.</p>
        <p><strong>Taking space- </strong>When a person, brand, organisation or authority talks over or replicates the actions of someone else who is already communicating the same message effectively.</p>
        <p><strong>Greenwashing - </strong>When a person or company represents themselves as environmentally responsible, when in fact they are not.</p>
        <p><strong>Sustainability - </strong>In terms of the environment, ‘sustainability’ is concerned with whether the environment and the world’s natural resources will be protected and maintained for future generations.</p>
        <p><strong>Linear economy - </strong>A linear economy is a traditional model that takes natural resources, makes products out of them and then disposes the products when people are done using them. This process is repeated with almost all goods on the planet, and leads to waste and environmental damage.</p>
        <p><strong>Circular economy - </strong>Also known as ‘circularity’, a circular economy is the opposite of a linear one. It aims to eliminate waste and promotes resources that can be continually reused.</p>
        <p><strong>Upcycling - </strong>When you take an existing object or material and update it, giving it a new lease of life instead of throwing it away. You can upcycle an old T-shirt by tailoring and customising it.</p>
        <p><strong>Regenerative agriculture - </strong>An approach to food and farming that values the conservation of natural resources, biodiversity and resilience to climate change.</p>
        <p><a rel="noopener noreferrer" target='_blank' className='click-link' href={"https://www.instagram.com/explore/tags/oootd/"}><strong>#OOOTD - </strong></a>A hashtag that stands for ‘old outfit of the day’, coined by slow fashion activist <a rel="noopener noreferrer" target='_blank' className='click-link' href={"https://www.instagram.com/venetialamanna/"}>@venetialamanna</a> as a response to the hashtag #OOTD (‘outfit of the day’) that promotes fast fashion.</p>
        <p><strong>The Green New Deal - </strong>As the world considers paths to economic recovery after Covid-19, the Green New Deal is a policy plan that aims to fundamentally restructure our economy in a way that addresses economic inequality and ensures a safe, habitable planet for the future.</p>
        <p><strong>The Femenist Green New Deal - </strong>A call for a policy plan that applies the principles of intersectional feminism to the Green New Deal. It considers and focuses on important feminist issues like reproductive justice, the care economy, sustainable production, women’s leadership and gender justice.</p>
        <p><strong>Fashion racism - </strong>The prejudice, discrimination, exploitation or enslavement inflicted on people working in the fashion or textiles industry because of their race. This is a systemic issue that has been normalised over centuries. An early example of fashion racism is the mass kidnapping and enslavement of Black people to work on cotton plantations for the profit of white slave owners.</p>
        <p><strong>Globalisation - </strong>The result of trade liberalisation in the 1980s (particularly free trade agreements like NAFTA and the WTO agreements), globalisation lets people and goods move quickly around the world so corporations can operate at an international scale. This has led to a global division of labour, meaning that brands give their workers in the Global North more respect, rights and money while workers in the Global South are exploited for maximum profit. </p>
        <p><strong>White innocence - </strong>The ways that white people ignore the fact that they benefit from white privilege, and that they have a part to play in dismantling it. For example, hiring more people of colour in a corporation as a way to ‘solve’ racism shifts the accountability from white people and places the task on people of colour.</p>
        <p><strong>Neo-colonialism </strong>A symptom of capitalism that enables those in power (countries and corporations) to exploit and dominate other nations through the tools of the international economy. For example, fashion brands are neo-colonial powers because they are in a ‘race to the bottom’, exploiting workers from the Global South to maximise their profits.</p>
        <p><strong>#PayUp - </strong>Is a campaign from RE/Make calling for brands to actually pay for the billions of dollars’ worth of cancelled garment industry orders. </p>
        <p><strong>Cultural appropriation - </strong> Also known as ‘cultural misappropriation’, this happens when a person or brand (usually from a ‘dominant’ culture) takes for their own use the fashion, iconography, trends or styles of another culture that is experiencing oppression. Cultural appropriation does not work the other way around; Black, Indigenous and people of colour (BIPOC) cannot appropriate from white people, because BIPOC do not hold collective power or privilege over white people.</p>


      
      <p className='articles-title'>Books</p>
        <p><strong>No Logo</strong><em> Naomi Klein (1999)</em></p>
        <p><strong>Cradle to Cradle</strong><em> Michael Braungart & William McDonough (2002)</em></p>
        <p><strong>Green Is the New Black</strong><em> Tamsin Blanchard (2007)</em></p>
        <p><strong>To Die For</strong><em> Lucy Siegle (2011)</em></p>
        <p><strong>Overdressed</strong><em> Elizabeth Cline (2012) </em></p>
        <p><strong>Refashioned</strong><em> Sass Brown (2013)</em></p>
        <p><strong>This Changes Everything</strong><em> Naomi Klein (2014)</em></p>
        <p><strong>Stitched Up </strong><em> Tansy Hoskins (2014)</em></p>
        <p><strong>Clothing Poverty</strong><em> Andrew Brooks (2015)</em></p>
        <p><strong>Fixing Fashion </strong><em> Michael Lavergne (2015)</em></p>
        <p><strong>Slave to Fashion </strong><em> Safia Minney (2017)</em></p>
        <p><strong>The Golden Thread </strong><em> Kassia St Clair (2018)</em></p>
        <p><strong>Rise & Resist</strong><em> Clare Press (2018)</em></p>
        <p><strong>Threads of Life </strong><em>Clare Hunter (2019)</em></p>
        <p><strong>The Conscious Closet</strong><em> Elizabeth Cline (2019)</em></p>
        <p><strong>Earth Logic</strong><em> Kate Fletcher & Mathilda Tham (2019)</em></p>
        <p><strong>Fashionopolis</strong><em> Dana Thomas (2019)</em></p>

      <p className='articles-title'>Films & Documentaries</p>
        <p><a rel="noopener noreferrer" target='_blank' className='click-link' href={"https://www.beforetheflood.com/"}><strong>Before The Flood </strong></a><em>Fisher Stevens (2016)</em></p>
        <p><a rel="noopener noreferrer" target='_blank' className='click-link' href={"https://www.youtube.com/watch?v=G4gyVRKxfm0"}><strong>Made in Bangladesh</strong></a><em> Rubaiyat Hossain (2019)</em></p>
        <p><a rel="noopener noreferrer" target='_blank' className='click-link' href={"https://priceoffree.com/?utm_source=youtube&utm_medium=video&utm_campaign=soulpancake"}><strong>The Price of Free</strong></a><em> Derek Doneen (2018) </em></p>
        <p><a rel="noopener noreferrer" target='_blank' className='click-link' href={"http://riverbluethemovie.eco/the-team/"}><strong>River Blue </strong></a><em> Roger Williams & David McIlvride (2017)</em></p>
        <p><a rel="noopener noreferrer" target='_blank' className='click-link' href={"https://truecostmovie.com/"}><strong>The True Cost</strong></a><em> Andrew Morgan (2015) </em></p>

        <p className='articles-title'>Online Resources</p>
        <p><a rel="noopener noreferrer" target='_blank' className='click-link' href={"https://cleanclothes.org/"}><strong>Clean Clothes Campaign</strong></a></p>
        <p><a rel="noopener noreferrer" target='_blank' className='click-link' href={"http://fashionrevolution.org/"}><strong>Fashion Revolution</strong></a></p>
        <p><a rel="noopener noreferrer" target='_blank' className='click-link' href={"http://fashionandrace.com/"}><strong>Fashion and Race Database</strong></a><em> Kimberly Jenkins</em></p>
        <p><a rel="noopener noreferrer" target='_blank' className='click-link' href={"http://melaninass.com/"}><strong>Melanin and Sustainable Style</strong></a><em> Dominique Drakeford</em></p>

      <p className='articles-title'>Articles</p>
        <p className='body-title'>BOOHOO AND SLAVE LABOUR</p>
        <p className='body-title'>The Sunday Times: <a rel="noopener noreferrer" target='_blank' className='click-link' href={"https://www.thetimes.co.uk/article/boohoo-fashion-giant-faces-slavery-investigation-57s3hxcth"}>Boohoo: fashion giant faces ‘slavery’ investigation</a></p>
        <p className='body-title'>The Guardian: <a rel="noopener noreferrer" target='_blank' className='click-link' href={"https://amp.theguardian.com/commentisfree/2020/jul/03/leicester-coronavirus-lockdown-is-no-surprise-to-its-garment-factory-workers"}>Leicester's coronavirus lockdown is no surprise to its garment factory workers</a></p>
        <p className='body-title'>The Financial Times: <a rel="noopener noreferrer" target='_blank' className='click-link' href={"https://www.ft.com/content/e427327e-5892-11e8-b8b2-d6ceb45fa9d0"}>Dark factories: labour exploitation in Britain’s garment industry</a></p>
        <p className='body-title'>Labour Behind The Label: <a rel="noopener noreferrer" target='_blank' className='click-link' href={"https://publications.parliament.uk/pa/cm201719/cmselect/cmenvaud/1952/report-summary.html"}>Report: Boohoo and COVID-19: The people behind the profit</a></p>
        <p className='body-title'>Channel 4 Dispatches: <a rel="noopener noreferrer" target='_blank' className='click-link' href={"https://www.channel4.com/press/news/undercover-britains-cheap-clothes-channel-4-dispatches"}>Undercover: Britain's Cheap Clothes</a></p>
        <p className='body-title'>UK Parliament: <a rel="noopener noreferrer" target='_blank' className='click-link' href={"https://publications.parliament.uk/pa/cm201719/cmselect/cmenvaud/1952/report-summary.html"}>Fixing fashion: clothing consumption and sustainability</a></p>
        <p className='body-title'>Parliament UK: <a rel="noopener noreferrer" target='_blank' className='click-link' href={"https://www.parliament.uk/business/committees/committees-a-z/commons-select/environmental-audit-committee/news-parliament-2017/chair-comments-boohoo-trade-union-recognition-17-19/"}>Chair demands answers from Boohoo on trade union recognition</a></p>
        <br/>
        <p className='body-title'>GARMENT WORKERS AND PAYUP</p>
        <p className='body-title'>Bloomberg: <a rel="noopener noreferrer" target='_blank' className='click-link' href={"https://www.bloomberg.com/news/articles/2020-03-23/europe-retailers-cancel-1-billion-of-bangladesh-garment-orders"}>European Retailers Scrap $1.5 Billion of Bangladesh Orders</a></p>
        <p className='body-title'>Open Democracy: <a rel="noopener noreferrer" target='_blank' className='click-link' href={"https://www.opendemocracy.net/en/oureconomy/garment-workers-are-facing-a-humanitarian-crisis-but-dont-blame-covid-19/"}>Garment workers are facing a humanitarian crisis – but don’t blame COVID-19</a></p>
        <p className='body-title'>The Guardian: <a rel="noopener noreferrer" target='_blank' className='click-link' href={"https://www.theguardian.com/global-development/2020/jun/20/we-have-no-money-for-food-or-rent-plight-of-bangladeshi-garment-makers"}>European Retailers Scrap $1.5 Billion of Bangladesh Orders</a></p>
        <p className='body-title'>The Guardian: <a rel="noopener noreferrer" target='_blank' className='click-link' href={"https://www.theguardian.com/fashion/2020/jun/24/zara-primark-factory-workers-myanmar-fired-union"}>Zara and Primark factory workers say they were fired after forming union</a></p>
        <p className='body-title'>The Guardian: <a rel="noopener noreferrer" target='_blank' className='click-link' href={"https://www.theguardian.com/global-development/2020/may/27/fast-fashion-pakistan-garment-workers-fight-for-rights-amid-covid-19-crisis"}>Fast fashion: Pakistan garment workers fight for rights amid Covid-19 crisis</a></p>
        <p className='body-title'>NewStatesman: <a rel="noopener noreferrer" target='_blank' className='click-link' href={"https://www.newstatesman.com/culture/2014/05/fashion-just-excuse-rich-exploit-poor?fbclid=IwAR0SDAHtgE97Ia5KFsiemiO68U-jDEPTuwqY85UMn8huGW6dT7eEgdOWUHo"}>“Fashion” is just an excuse for the rich to exploit the poor</a></p>
        <p className='body-title'>Nikkei - Asian Review: <a rel="noopener noreferrer" target='_blank' className='click-link' href={"https://asia.nikkei.com/Economy/Trade/Cambodia-garment-factories-call-for-wage-cuts-as-pandemic-bites"}>Cambodia garment factories call for wage cuts as pandemic bites</a></p>
        <p className='body-title'>Remake: <a rel="noopener noreferrer" target='_blank' className='click-link' href={"https://remake.world/stories/news/the-global-impact-of-delayed-payments-and-factory-closures-for-garment-makers/"}>The Global Impact of Delayed Payments and Factory Closures for Garment Makers</a></p>
        <p className='body-title'>The Guardian: <a rel="noopener noreferrer" target='_blank' className='click-link' href={"https://www.theguardian.com/global-development/2020/jun/16/jailed-for-a-facebook-post-garment-workers-rights-at-risk-during-covid-19"}>Jailed for a Facebook post: garment workers' rights at risk during Covid-19</a></p>

        {/* 

Billionaire references for this post
Kylie Jenner and Philip Green
 */}
{/* 
      <p className='articles-title'>In the meantime ...</p>
        <p className='body-content'>CHECK OUT OUR <a  rel="noopener noreferrer" target='_blank' className='link' href={instagramURL}>INSTAGRAM</a> PAGE ... </p> */}
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
    </div>
  );
}