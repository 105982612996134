import React from 'react';
import './Magalie.css';
import {FullBio} from '../../components/FullBio';


export function Magalie () {
  const instagramURL= "https://www.instagram.com/ici.jozi/";
  const bio = "Magalie is a nomad illustrator, originally from Paris but on the road as often as she can. After graduating in set design in 2018, she left France to travel Australia for two years and slowly moved away from the movie industry. Thinking that being a digital illustrator would suit her nomadic lifestyle perfectly, she decided in 2019 to take a chance as a freelance. Always inspired by her love of nature and environment, she’s constantly on the hunt of new places to visit and new people to meet. Committed to environmental issues, conservation and social justice, she wants to use her art to inform people and spread awareness.";
  const websiteURL="https://www.instagram.com/ici.jozi/";
  return(
    <div className='resources'>
      <FullBio name='Magalie' content={bio} instagram={instagramURL} website={websiteURL}/>
    </div>
  );
}