import React from 'react';
import './Swatee.css';
import {FullBio} from '../../components/FullBio';


export function Swatee () {
  const instagramURL= "https://www.instagram.com/swateedeepak/";
  const bio = "Swatee works with private and public foundations in strategy development and design, works with individuals and families on their wealth redistribution strategies and oversees a portfolio of businesses and start-ups across sustainable fashion, music, events and the arts. Swatee has significant experience in supporting movements, starting and leading organisations through growth and transitions across feminist philanthropy investments, human rights based development agencies, socially minded businesses and the arts. She was previously Director of With and For Girls and The Stars Foundation and is a Board member of EMpower - The Emerging Markets Foundation and the Global Fund for Children. She is Indian and British and draws strength from bridging and convening for collaborative action across all she does to work for a world of justice and liberation for everyone.";
  const websiteURL="https://twitter.com/swatee";
  return(
    <div className='resources'>
      <FullBio name='Swatee' content={bio} instagram={instagramURL} website={websiteURL}/>
    </div>
  );
}