import React from 'react';
import './Gina.css';
import {FullBio} from '../../components/FullBio';


export function Gina () {
  const instagramURL= "https://www.instagram.com/gina_bak/";
  const bio = "Gina is a yogi linguist turned programmer. She’s an anglophone Korean born in Ecuador, and has lived in Mexico, South Korea, USA, China, and now resides in Canada. She currently spends her days solving problems as a passionate Shopify Web Developer intern, and works furiously to find ways to make the garment industry more ethical and sustainable through research, technology, and advocacy on the side.";
  const websiteURL="https://www.ginabak.com";
  return(
    <div className='resources'>
      <FullBio name='Gina' content={bio} instagram={instagramURL} website={websiteURL}/>
    </div>
  );
}