import React from 'react';
import './Devi.css';
import {FullBio} from '../../components/FullBio';


export function Devi () {
  const instagramURL= "https://www.instagram.com/djlo.djlo/";
  const bio = "Devi works in social justice and philanthropy, supporting funders and feminists to redistribute resources. She has over a decade of experience working with women’s funds: she previously was Co-Executive Director of FRIDA | The Young Feminist Fund and currently is a Board Member of Urgent Action Fund Asia and Pacific. In addition to supporting strategy and program design, she is exploring more work on narrative shifts and cultural changes for feminist movements and philanthropy. She is Cambodian and US-American, and an aspiring artist!";
  const websiteURL="https://www.devileiperomalley.com/";
  return(
    <div className='resources'>
      <FullBio name='Devi' content={bio} instagram={instagramURL} website={websiteURL}/>
    </div>
  );
}