import React from 'react';
import './FullBio.css';
import {ProfileImage} from '../ProfileImage';

export function FullBio (props) {
    return(
      <div className='fullbio'>
        <p className='title'>Meet {props.name}</p>
          <ProfileImage img={(props.name).toLowerCase()}/>
          <p className='fullbio-bio'>
          {props.content}
          </p>
          <p className='body-content'>See {props.name} on <a  rel="noopener noreferrer" target='_blank' className='link' href={props.instagram}>INSTAGRAM</a></p>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
      </div>
  );
}