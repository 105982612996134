import React from 'react';
import './About.css';
import {Bio} from '../../components/Bio';


export function About () {
  const bioArray = ['venetia', 'swatee', 'devi', 'ruby', 'judith', 'holly', 'magalie', 'sophie', 'gina'];
  return(
    <div className='about'>
      <p className='title'>Who are we?</p>
      <p className='about-body'>
        We are a group of concerned feminists with networks in philanthropy, climate activism, the arts and sustainable fashion.
        <br/>
        We will be collaborating with workers groups and campaigns to spotlight their situations and demands, as well as with social media influencers and key media outlets to raise greater awareness and action.
        <br/>
        <br/>
      <a className='email-link' href="mailto:hello@rememberwhomadethem.com?subject=Hello!">email us</a>
      </p>
      <p className='title'>Bios</p>
      <section className='bio-grid'>
        <section className='grid-container'>
          {bioArray.map((bio)=> {
            return(
              <section className='grid-item'>
                <Bio key={bio} name={bio}/>
              </section>
            );
            })
          }
        </section>
      </section>

      <p className='title'>Special Thanks</p>
      <p className='about-body'>Thanks to the Do The Green Thing Team and to Luke Powell at Pentagram for co-creating the image, branding and messaging for Remember Who Made Them.
      <br/>
      <br/>
      <a rel="noopener noreferrer" target='_blank' className='about-click-link' href={"http://dothegreenthing.com/"}>Do The Green Thing</a> is a public service for the planet, using creativity to tackle climate change. The team is run out of <a rel="noopener noreferrer" target='_blank' className='about-click-link' href={"http://pentagram.com/"}>Pentagram Design</a>, the world's largest independent design consultancy. Do The Green Thing is: Ailbhe Larkin, Ashley Johnson, Chloe Ting, Katee Hui, Naresh Ramchandani, Robyn Cusworth and Shóna Slemon.
      </p>

      <p className='title'>FAQs</p>
      <p className='about-body'>
        Do you have a question? Please see our working <a rel="noopener noreferrer" target='_blank' className='about-click-link' href={"https://docs.google.com/document/d/1R6rQO15dLnzjQ1SJMevuO62aSrR88kLjj-7LAzKPh6s/edit"}><strong>Google Doc</strong></a> that we will be updating as we collect more questions and answers.
        If your question isn’t there, don’t hesitate to <a rel="noopener noreferrer" target='_blank' className='about-click-link' href="mailto:hello@rememberwhomadethem.com?subject=FAQs"><strong> get in touch</strong></a>.
      </p>
      <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
    </div>
  );
}