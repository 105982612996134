import React from 'react';
import './Holly.css';
import {FullBio} from '../../components/FullBio';


export function Holly () {
  const instagramURL= "https://www.instagram.com/hollymaguireuk/";
  const bio = "Holly is a freelance Illustrator based in Bristol, UK. Her work features lots of colour, pattern & botanicals and often depicts fun and relatable topics as well as the things that are important to her. Holly is passionate about using her work to highlight environmental, social and political issues in a friendly and digestible way. She has worked on a variety of projects throughout her career from books to puzzles and homeware but Remember Who Made Them is one of her most important and meaningful collaborations to date!";
  const websiteURL="https://www.hollymaguire.co.uk/";
  return(
    <div className='resources'>
      <FullBio name='Holly' content={bio} instagram={instagramURL} website={websiteURL}/>
    </div>
  );
}