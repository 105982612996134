import React from 'react';
import PodcastImage from './images/podcast-image.jpg';
import './Podcast.css';


export function Podcast () {
  const podcastURL = 'https://open.spotify.com/episode/4f4pqQ5jc4vcYswMNysTwL?si=S8-e6fqGShyj1A0D8ZeSdA';
  return(
    <div className='podcast'>
      <p className='podcast-title'>Listen, Learn, Remember</p>
       <section className='grid'>
        <section className='grid-item'>
          <a  rel="noopener noreferrer" target='_blank' href={podcastURL}>
            <img src={PodcastImage} alt="Garment Workers for podcast" className='podcast-image'/>
          </a>
        </section>
        <section className='grid-item'>
        <p className='podcast-body'>
          Remember Who Made Them is a six part podcast series, digital campaign and fundraiser that aims to help energise a new solidarity economy in fashion.
          <br/>
          <br/>
          In collaboration with existing campaigns, activists and workers unions, we are seeking to amplify the messages to show global solidarity with the people making our clothes and to ensure companies who have profited take responsibility to support them at this time.
          <br/>
          <br/>
          We also want to empower our listeners with the adequate tools and resources to donate, buy less, buy better and hold fashion brands accountable. These episodes will be jam-packed with useful information, and the show is aimed at anyone with an interest in fashion, social justice - or both!
        </p>
        </section>
       </section>
       <br/>
       <br/>
       <br/>
       <br/>
       <br/>
    </div>
  );
}