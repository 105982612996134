import React from 'react';
import {NavLink} from 'react-router-dom';
import './Nav.css';

const pages = [
  {
    path:'/',
    title: 'Manifesto',
  },
  {
    path: '/podcast',
    title: 'Podcast',
  }, 
  {
    path: '/resources',
    title: 'Resources',
  },
  {
    path: '/about',
    title: 'About',
  }, 
  {
    path: '/action',
    title: 'Action',
  }
];

export function Nav({isCentered}) {
  const links = pages.map((page) => (
    <NavLink to={page.path} 
    exact className='nav-link' 
    activeClassName='nav-link__active' 
    key={page.title}>
      <span>
        {page.title}
      </span>
    </NavLink>
  ));
  return (
    <div className={`nav ${isCentered ? 'nav__centered' : ''}`}>
      {links}
    </div>
  )
}