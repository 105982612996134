import React from 'react';
import { Routes, Background } from './components';
import {
  BrowserRouter as Router, 
  Switch,
} from 'react-router-dom';
import { PageLayout } from './components/PageLayout/PageLayout';

import './App.css'; 

function App() {
  return(
    <div className='app'>
      <Background />
      
      <div className='alignment'>
        <Router>
          <Switch>
              <PageLayout>
                <Routes />
              </PageLayout>
          </Switch>
        </Router>
      </div>
    </div>
  );
}

export default App;
