import React from 'react';
import './Bio.css';
import {ProfileImage} from '../ProfileImage';

export function Bio (props) {
  return(
    <div className='bio'>
      <ProfileImage img={props.name}/>
      <br/>
      <a rel="noopener noreferrer" target='_blank' href={`https://rememberwhomadethem.com/about-${props.name}`}className='bio-name' >{props.name}</a>
    </div>
);
}