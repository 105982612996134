import React from 'react';
import './Action.css';

export function Action () {
  // const instagramURL="https://www.instagram.com/rememberwhomadethem/";
  const patreonURL="https://www.patreon.com/Rememberwhomadethem?fan_landing=true";
  const remakeURL="https://www.change.org/p/unless-gap-primark-c-a-payup-millions-of-garment-makers-will-go-hungry";
  const laboursignURL="https://labourbehindthelabel.org/covid-19-support-workers/";
  const traidURL="https://action.traidcraft.org.uk/fast-fashion-crisis-take-action";
  const ausURL="https://actions.oxfam.org/australia/actnow/what-she-makes-covid-19/en/";

  const labourURL="https://labourbehindthelabel.org/";
  const cleanURL="https://www.instagram.com/cleanclothescampaign/";
  const remakeworldURL="https://remake.world/";

  const awajURL = "https://www.gofundme.com/f/7422eu-fund?utm_source=customer&utm_medium=copy_link-tip&utm_campaign=p_cp+share-sheet";
  const sriURL= "https://www.gofundme.com/f/n7mj42-support-sri-lankan-garment-makers";
  const laURL="https://garmentworkercenter.ourpowerbase.net/civicrm/contribute/transact?reset=1&id=2";



  return(
    <div className='action'>
      <p className='title'>Donate</p>
      <p className='action-body'>If you want to take action to support building garment worker power and remember who made them, you can join the Remember Who Made Them Patreon and <a rel="noopener noreferrer" target='_blank' className='link' href={patreonURL}>donate here</a>.</p>
      <p>We are here to elevate, not compete with, existing movements and campaigns.
        <br/>
        <br />
      Below is an evolving a list of brilliant work being done in support of energising a new solidarity economy in fashion, which we encourage you to take action on:</p>
      <p className='title'>Sign</p>
      <p>We are here to elevate, not compete with, existing movements and campaigns.</p>
      <p className='body-title'>Remake: <a rel="noopener noreferrer" target='_blank' className='click-link' href={remakeURL}>Gap, Primark, C&A #PayUp for orders, save lives</a> </p>
      <p className='body-title'>Labour Behind the Label: <a rel="noopener noreferrer" target='_blank' className='click-link' href={laboursignURL}>To UK fashion brands</a> </p>     
      <p className='body-title'>Traidcraft: <a rel="noopener noreferrer" target='_blank' className='click-link' href={traidURL}>To UK fashion brands</a></p>     
      <p className='body-title'>Oxfam Australia: <a rel="noopener noreferrer" target='_blank' className='click-link' href={ausURL}>To Australian fashion brands</a></p>     

      <p className='title'>Support</p>
      <p className='body-title'><a rel="noopener noreferrer" target='_blank' className='click-link' href={labourURL}>Labour Behind The Label</a></p>  
      <p className='body-title'><a rel="noopener noreferrer" target='_blank' className='click-link' href={cleanURL}>Clean Clothes Campaign</a></p>  
      <p className='body-title'><a rel="noopener noreferrer" target='_blank' className='click-link' href={remakeworldURL}>Remake</a></p>  

      <p className='title'>Donate Direct</p>
      <p className='body-title'>Bangladesh: <a rel="noopener noreferrer" target='_blank' className='click-link' href={awajURL}>AWAJ Foundation’s GoFundMe</a></p>
      <p className='body-title'>Sri Lanka: <a rel="noopener noreferrer" target='_blank' className='click-link' href={sriURL}>Support Sri Lankan Garment Makers on GoFundMe</a></p>
      <p className='body-title'>Los Angeles: <a rel="noopener noreferrer" target='_blank' className='click-link' href={laURL}>Garment Worker Center’s COVID-19 Emergency Relief Fund</a></p>
          
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>    
    </div>
  );
}