import React from 'react';
import './Sophie.css';
import {FullBio} from '../../components/FullBio';


export function Sophie () {
  const instagramURL= "https://www.instagram.com/lifeslines_sophie/";
  const bio = "Sophie is a Scottish illustrator living in England, UK. She is a general dabbler of creative things; graphic design, earrings, painted plant pots, and woven wall hangings, to name a few. Her main objective is to produce pieces that provoke a feeling of joy in the viewer or wearer. When Sophie is not creating art for her shop, she is often working on one-off commissions; something she enjoys very much. She also tries to weave her passion for issues such as the environment, slow fashion, inclusivity, and other various social or political matters, into her artwork. This ‘Remember Who Made Them’ campaign is close to her heart and she is over the moon to be a part of it.";
  const websiteURL="http://etsy.me/2Yy6yw9";
  return(
    <div className='resources'>
      <FullBio name='Sophie' content={bio} instagram={instagramURL} website={websiteURL}/>
    </div>
  );
}