import React from 'react';
import './ProfileImage.css';
// src={`/img/${this.props.product.src}.png`} 

export function ProfileImage (props) {
    return(
    <div className='profile'>
      <img className='profile-image' src={require(`../../pages/images/${props.img}.jpg`)} alt={`profile of ${props.img}`}></img>
    </div>
  );
}