import React from 'react';
import './Judith.css';
import {FullBio} from '../../components/FullBio';


export function Judith () {
  const instagramURL= "https://www.instagram.com/judith_p.raynault/";
  const bio = "Judith is a graphic designer and illustrator based in London but originally from Montréal. After working 10 years in fashion design and finding herself more interested in the graphic design part of the work, she made the switch and never looked back. Her design work focuses on editorials, publications, branding and art direction. Her illustrations are inspired by people she admires, striking images, books, podcasts and snippets of everyday life. She applies her creativity to elevate people and businesses who help, educate or inspire. Because, well, it seems obvious that’s what makes us better and more fulfilled humans.";
  const websiteURL="https://judithpraynault.com/";
  return(
    <div className='resources'>
      <FullBio name='Judith' content={bio} instagram={instagramURL} website={websiteURL}/>
    </div>
  );
}
