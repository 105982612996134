import React from 'react';
import './Manifesto.css';

export function Manifesto(){
  const instagramURL= "https://www.instagram.com/explore/tags/rememberwhomadethem/";
  return(
    <>
    <div className='manifesto'>
      We all love clothes.
      <br/>
      Let's remember who made them.
    </div>
    <br/>
    <section className='mani-section'>
      Remember Who Made Them is helping to energise a new solidarity economy in fashion:
      <br/>
      <br/>
      An economy that centres the health and wellbeing of workers over corporate profits
      <br/>
      An economy that cares about the social and environmental impact of the clothes we buy
      <br/>
      And an economy that builds power in the communities where our clothes are made
      <br/>
      <br/>
      By celebrating the labour and dignity of garment workers
      <br/>
      By demanding fashion brands and their owners be held accountable to pay up and do better
      <br/>
      By elevating, not competing with, existing movements and campaigns
      <br/>
      And by refusing to return to the way things were before
      <br/>
      We know we can transform the future of fashion
      <br/>
      <br/>
      Clothes are powerful
      <br/>
      We use them to express ourselves and explore our creativity
      <br/>
      But no matter what clothes we wear, we should always remember the people who made them
      <br/>
      <br/>
      <a className='link'  rel="noopener noreferrer" target='_blank' href={instagramURL}>#RememberWhoMadeThem</a>
      <br/>
    </section>
    <br/>
    <br/>
    </>
  );
}