//Components
import React from 'react';
import './Background.css';
import {Logo} from '../Logo';

export function Background (){
    return(
        <div className='background'>
            <Logo />
        </div>
    );
}